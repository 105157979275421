
$font-Size:17px;
$primaryColor:#07668f;
$accentColor:#91071e;
@font-face{
    font-family: "VEXA-1-1";
    src: url('/public/VEXA-1-1.ttf') ;
}

*{
    font-family:Tahoma;
    font-size:$font-Size ;
    box-sizing: border-box;
    //line-height:1rem;
}
body{
    padding: 0px;
    margin: 0px;
}
.primary
{
    color:$primaryColor;
}
.primaryDark
{
    color:white;
}
.primaryBackground
{
    background-color:$primaryColor;
}
.accent{
    color:$accentColor;
}
.accentBackground
{
    background-color:$accentColor;
}

.activeaccentBackground:hover
{
    background-color:$accentColor;
}


a.dark {color:$primaryColor; text-decoration: none;background-color:white;}
a.dark:link {color:$primaryColor;background-color: white;}
a.dark:visited {color:$primaryColor;background-color: white;}
a.dark:hover {color:white;background-color: gray}
a.dark:active {color:$primaryColor;background-color:white;} 

a.light {color:$primaryColor; text-decoration: none;background-color:white;}
a.light:link {color:$primaryColor;background-color: white;}
a.light:visited {color:$primaryColor;background-color: white;}
a.light:hover {color:$accentColor;background-color: lightyellow;}
a.light:active {color:$primaryColor;background-color:white;} 

table {

    margin:10px auto;
    border-collapse:collapse;
    border: 0px;
}

tr:hover{
    background-color: lightyellow;
    
}

th{
    font-size: 0.8rem;
    background-color: white;
    color: $primaryColor;
    padding: 0.5rem;
    border-bottom: 2px solid $primaryColor;
}

th:first-child
{
    text-align:right;
}

td{
    font-size: 0.8rem;
    padding: 0.5rem;
    text-align: center;
    border-bottom: 1px solid gray;
}
td:first-child
{
    text-align:right;
}



//Form Styel
.caption
{
    padding:0.3rem;
    color:$primaryColor;
    font-size: 0.8rem;
}

input,select,button{
    width:100%;
    font-size: 0.8rem;
    padding:0.5rem;
    text-align: center;
    border:.1rem solid $primaryColor;
    margin-bottom: 0.5rem;
    border-radius: 0.2rem;
}

input:focus,select:focus{
    background-color: lightyellow;
    border:0.1rem solid $accentColor;
    font-size: 0.8rem;
}

button.accent{
    width:100%;
    font-size: 0.8rem;
    padding:0.5rem;
    text-align: center;
    border:.1rem solid $primaryColor;
    background-color: gray;
    color:white;
    margin-bottom: 0.5rem;
    border-radius: 0.2rem;
    cursor: pointer;
}
button.accent:hover{
    background-color: $accentColor;
    border:none;
    font-size: 0.8rem;
}

button:hover{
    background-color:green;
    color:white;
    border:none;
    font-size: 0.8rem;
    cursor: pointer;
}


.input-value {
    width:100%;
    font-size: 0.8rem;
    padding:0.5rem;
    text-align: center;
    border:.1rem solid $primaryColor;
    margin-bottom: 0.5rem;
    border-radius: 0.2rem;
}

//tree

.treeCaption
{
    font-size: 0.8rem;
    background-color: white;
    color: $primaryColor;
    padding: 0.5rem;
    border-bottom: 2px solid $primaryColor;
    margin: 10px auto;
    font-weight: bold;
}