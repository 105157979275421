@font-face {
  font-family: "VEXA-1-1";
  src: url("/public/VEXA-1-1.ttf");
}
* {
  font-family: Tahoma;
  font-size: 17px;
  box-sizing: border-box;
}

body {
  padding: 0px;
  margin: 0px;
}

.primary {
  color: #07668f;
}

.primaryDark {
  color: white;
}

.primaryBackground {
  background-color: #07668f;
}

.accent {
  color: #91071e;
}

.accentBackground {
  background-color: #91071e;
}

.activeaccentBackground:hover {
  background-color: #91071e;
}

a.dark {
  color: #07668f;
  text-decoration: none;
  background-color: white;
}

a.dark:link {
  color: #07668f;
  background-color: white;
}

a.dark:visited {
  color: #07668f;
  background-color: white;
}

a.dark:hover {
  color: white;
  background-color: gray;
}

a.dark:active {
  color: #07668f;
  background-color: white;
}

a.light {
  color: #07668f;
  text-decoration: none;
  background-color: white;
}

a.light:link {
  color: #07668f;
  background-color: white;
}

a.light:visited {
  color: #07668f;
  background-color: white;
}

a.light:hover {
  color: #91071e;
  background-color: lightyellow;
}

a.light:active {
  color: #07668f;
  background-color: white;
}

table {
  margin: 10px auto;
  border-collapse: collapse;
  border: 0px;
}

tr:hover {
  background-color: lightyellow;
}

th {
  font-size: 0.8rem;
  background-color: white;
  color: #07668f;
  padding: 0.5rem;
  border-bottom: 2px solid #07668f;
}

th:first-child {
  text-align: right;
}

td {
  font-size: 0.8rem;
  padding: 0.5rem;
  text-align: center;
  border-bottom: 1px solid gray;
}

td:first-child {
  text-align: right;
}

.caption {
  padding: 0.3rem;
  color: #07668f;
  font-size: 0.8rem;
}

input, select, button {
  width: 100%;
  font-size: 0.8rem;
  padding: 0.5rem;
  text-align: center;
  border: 0.1rem solid #07668f;
  margin-bottom: 0.5rem;
  border-radius: 0.2rem;
}

input:focus, select:focus {
  background-color: lightyellow;
  border: 0.1rem solid #91071e;
  font-size: 0.8rem;
}

button.accent {
  width: 100%;
  font-size: 0.8rem;
  padding: 0.5rem;
  text-align: center;
  border: 0.1rem solid #07668f;
  background-color: gray;
  color: white;
  margin-bottom: 0.5rem;
  border-radius: 0.2rem;
  cursor: pointer;
}

button.accent:hover {
  background-color: #91071e;
  border: none;
  font-size: 0.8rem;
}

button:hover {
  background-color: green;
  color: white;
  border: none;
  font-size: 0.8rem;
  cursor: pointer;
}

.input-value {
  width: 100%;
  font-size: 0.8rem;
  padding: 0.5rem;
  text-align: center;
  border: 0.1rem solid #07668f;
  margin-bottom: 0.5rem;
  border-radius: 0.2rem;
}

.treeCaption {
  font-size: 0.8rem;
  background-color: white;
  color: #07668f;
  padding: 0.5rem;
  border-bottom: 2px solid #07668f;
  margin: 10px auto;
  font-weight: bold;
}/*# sourceMappingURL=style.css.map */